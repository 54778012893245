import React, { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { UIButton, UIFilePicker, Modal } from 'finbox-ui-kit';
import { useModal } from 'finbox-ui-kit/utils/hooks';
import { TUser } from '@/types/manager';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { useAuthContext } from '@/context/auth.context';
import styles from './user-avatar.module.scss';

type UserAvatarProps = {
    user: TUser;
    onChange?: () => void;
    editable?: boolean;
};

export function UserAvatar({ user, onChange, editable }: UserAvatarProps): React.ReactElement {
    const editor = useRef(null);
    const [ newAvatarFile, setNewAvatarFile ] = useState<File>(null);
    const [ scale, setScale ] = useState(1);
    const { fetchUser } = useAuthContext();
    const { show, props } = useModal({
        onClose() {
            setNewAvatarFile(null);
        },
    });
    const { fetch } = useApiClient({
        method: 'patch',
        url: `/users/${ user.id }/avatar`,
    });

    function handlerPickFile(files: File[]) {
        setNewAvatarFile(files[0]);
    }

    async function handlerUpload() {
        const canvas = editor.current.getImageScaledToCanvas();
        const blob: Blob | null = await new Promise((resolve) => {
            canvas.toBlob((blob: (Blob | null)) => {
                resolve(blob);
            }, 'image/png', 1);
        });
        await fetch({}, {
            attachments: [ [ 'avatar', new File([ blob ], "name", { type: 'image/png' }) ] ],
        });
        await fetchUser();
        onChange && onChange();
        props.onClose();
    }

    return (
        <div className={ styles.userAvatar }>
            { user.avatar && (
                <div
                    className={ styles.userAvatarImage }
                    style={ { backgroundImage: `url(${ user.avatar })` } }
                />
            ) }

            { !user.avatar && (
                <div className={ styles.userAvatarNoImage }>
                    { user.surname[0] }{ user.name[0] }
                </div>
            ) }

            { editable && (
                <div className={ styles.userAvatarEditButton }>
                    <UIButton
                        icon='pencil'
                        content='Редактировать'
                        onClick={ () => show() }
                    />
                </div>
            ) }
            <Modal { ...props } size='tiny' showCloseButton>
                <Modal.Header>Редактирование аватара</Modal.Header>
                <Modal.Content>
                    { newAvatarFile && (
                        <div className='p2'>
                            <AvatarEditor
                                ref={ editor }
                                image={ newAvatarFile }
                                width={ 276 }
                                height={ 276 }
                                border={ 0 }
                                borderRadius={ 138 }
                                color={ [ 255, 255, 255, 0.6 ] } // RGBA
                                scale={ scale }
                                rotate={ 0 }
                            />
                            <input
                                type='range'
                                min={ 1 }
                                max={ 5 }
                                step={ 0.1 }
                                style={ { width: '100%' } }
                                onChange={ (e) => setScale(parseFloat(e.target.value)) }
                                value={ scale }
                            />
                        </div>
                    ) }

                    <UIFilePicker
                        accept={ {
                            'image/jpeg': [],
                            'image/png': [],
                        } }
                        onChange={ handlerPickFile }
                        maxFiles={ 1 }
                        buttonText='Выберите изображение jpg или png'
                        showPickedFiles={ false }
                    />
                </Modal.Content>
                <Modal.Actions>
                    <UIButton
                        content='Сохранить'
                        onClick={ handlerUpload }
                        primary
                    />
                    <UIButton
                        content='Отмена'
                        onClick={ () => props.onClose() }
                    />
                </Modal.Actions>
            </Modal>
        </div>
    );
}