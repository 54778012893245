import React, { useCallback, useState } from 'react';

import { useRecoilValue } from 'recoil';
import { useForm, FormProvider } from 'react-hook-form';
import { UIBox } from 'finbox-ui-kit/components/box';
import { LoginForm } from '@/components/login/components/login-form';
import { ConfirmCodeForm, TCodeForm } from '@/components/login/components/confirm-code-form';
import { PRODUCTION } from '@/consts';
import { AuthState } from '@/state/auth.state';
import { codeScheme } from '@/components/login/schemas/code.scheme';
import { useAuthContext } from '@/context/auth.context';
import { useApiClient } from '@/libs/api-client/use-api-client';
import styles from './login.module.scss';


const RESEND_TIMEOUT = 30; // seconds
let BUFFER: string | number | NodeJS.Timeout;

const LoginPage = () => {
    const { doAuthUser, isLoadingCheckAuth } = useAuthContext();
    const isAuthorized = useRecoilValue(AuthState);
    const [ token, setToken ] = useState<string>('');
    const [ login, setLogin ] = useState<string>(null);
    const [ password, setPassword ] = useState<string>(null);
    const [ error, setError ] = useState<string>(null);
    const [ timer, setTimer ] = useState(0);

    const { fetch: fetchLogin, loading: isLoadingLogin } = useApiClient({
        method: 'post',
        url: '/auth/login/',
        useToken: false,
        showErrorToast: false,
    });

    const { fetch: fetchConfirm, loading: isLoadingConfirm } = useApiClient({
        method: 'post',
        url: '/auth/confirm/',
        useToken: false,
        showErrorToast: false,
    });

    const isLoading = isLoadingLogin || isLoadingConfirm;

    const methods = useForm<TCodeForm>({
        resolver: codeScheme,
    });

    const { setValue } = methods;

    const startTimer = useCallback((seconds: number) => {
        setTimer(seconds);
        if (seconds > 0) {
            BUFFER = setTimeout(() => {
                startTimer(seconds - 1);
            }, 1000);
        }
    }, []);

    const codeSubmitHandler = useCallback(async (code: string, accessToken?: string) => {
        setError(null);
        try {
            const data = await fetchConfirm({
                token: accessToken || token,
                code,
            });
            if (data.error) {
                setError(data.error);
                return;
            }
            await doAuthUser(data.token);
        } catch (err) {
            setError(err.message);
            if ([ 40303, 40003 ].includes(err.data.code)) {
                setToken(null);
            }
            if (err.data.code === 1000005) {
                setValue('code', null);
            }
        }
    }, [ doAuthUser, fetchConfirm, setValue, token ]);

    const loginSubmitHandler = useCallback(async ({ login, password }) => {
        setError(null);
        clearTimeout(BUFFER);
        startTimer(RESEND_TIMEOUT);
        try {
            const data = await fetchLogin({
                login,
                password,
            });

            if (data.success) {
                if (data.accessToken) {
                    await doAuthUser(data.accessToken);
                }

                setToken(data.token);
                setLogin(login);
                setPassword(password);

                if (!PRODUCTION && data.code) {
                    await codeSubmitHandler(
                        data.code,
                        data.token,
                    );
                }
            } else {
                setError(data.error);
            }

        } catch (err) {
            setError(err.message);
        }
    }, [ codeSubmitHandler, doAuthUser, fetchLogin, startTimer ]);

    const codeResendHandler = () => loginSubmitHandler({ login, password });
    const codeCancelHandler = () => setToken(null);

    return (
        <div className={ styles.signIn }>
            { !isLoadingCheckAuth && (
                <div className={ styles.signInWrapper }>
                    <UIBox fluid>
                        <div className={ styles.signInHeader }>
                            <h1>Авторизация</h1>
                        </div>
                        { (!isAuthorized && !token) && (
                            <LoginForm
                                isFetching={ isLoading }
                                onSubmit={ loginSubmitHandler }
                                error={ error }
                            />
                        ) }
                        { (!isAuthorized && token) && (
                            <FormProvider { ...methods }>
                                <ConfirmCodeForm
                                    timer={ timer }
                                    isFetching={ isLoading }
                                    onSubmit={ codeSubmitHandler }
                                    onResend={ codeResendHandler }
                                    onCancel={ codeCancelHandler }
                                    error={ error }
                                />
                            </FormProvider>
                        ) }
                    </UIBox>
                </div>
            ) }
        </div>
    );
};

export default LoginPage;
