import React, { useCallback, useRef, useState } from 'react';
import copy from 'copy-to-clipboard';

import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { UIButton, UIIcon, UIPopup } from 'finbox-ui-kit';
import { useMediaQuery, useToggle } from 'finbox-ui-kit/utils/hooks';
import { TUISize } from 'finbox-ui-kit/types/common';
import {
    formatNumber,
    normalizePhone,
    prettifyPhone,
} from '@/utils';
import { MessageDialog } from '@/common/message-dialog';
import { API_URLS, CHANNEL, CHANNEL_ICON, CHANNEL_ICON_COLOR } from '@/consts';
import { AuthUser } from '@/state/auth.state';
import { OptionsList, TOption, TSelectHandler } from '@/common/options-list';
import { useApiClient } from '@/libs/api-client/use-api-client';

type TPhoneProps = {
    number: string | number;
    whatsappNumber?: string | number;
    viberNumber?: string | number;
    text?: string;
    inline?: boolean;
    inlineIconsSize?: TUISize;
}


const replacePatterns = (text, user) => {
    const patterns = {
        lead_manager: user.name,
        manager_name: user.name,
        manager_phone: prettifyPhone(user.phone),
        manager_mail: user.mail,
    };
    return Object.keys(patterns).reduce((res, pattern) => {
        const re = new RegExp(`#${pattern}#`, 'gi');
        return res.replace(re, patterns[pattern]);
    }, text);
};


const Phone = ({
    number,
    whatsappNumber,
    viberNumber,
    text,
    inline = false,
    inlineIconsSize = 'small',
}: TPhoneProps) => {
    const ref = useRef<HTMLDivElement>();
    const { on, toggle } = useToggle();
    const isMobile = useMediaQuery('md');
    const user = useRecoilValue(AuthUser);
    const [ channel, setChannel ] = useState<CHANNEL>(null);
    const [ isMessageDialogOpen, setIsMessageDialogOpen ] = useState(false);

    const { fetch } = useApiClient({
        method: 'post',
        url: API_URLS.SMS.SEND,
        successMessage: 'Сообщение отправлено',
    });

    const messageDialogCloseHandler = useCallback(() => {
        setIsMessageDialogOpen(false);
    }, []);

    const SMSClickHandler = useCallback(() => {
        setChannel(CHANNEL.SMS);
        setIsMessageDialogOpen(true);
    }, []);

    const whatsappClickHandler = useCallback(() => {
        setChannel(CHANNEL.WHATSAPP);
        setIsMessageDialogOpen(true);
    }, []);

    const telegramClickHandler = useCallback(() => {
        window.open(
            `tg://resolve?phone=${ normalizePhone(whatsappNumber || number) }&text=${ text ? encodeURI(text) : '' }`,
            '_blank',
        );
        return false;
        // setChannel(CHANNEL.TELEGRAM);
        // setIsMessageDialogOpen(true);
    }, [ number, text, whatsappNumber ]);

    const copyClickHandler = useCallback(() => {
        copy(formatNumber(number));
        toast.info('Номер скопирован');
    }, [ number ]);

    const sendHandler = useCallback(async (text, customChannel: CHANNEL = null) => {
        switch (customChannel || channel) {
            case CHANNEL.WHATSAPP: {
                window.open(
                    encodeURI(`whatsapp://send?phone=+${ normalizePhone(number) }&text=${ replacePatterns(text, user) }`),
                    // eslint-disable-next-line max-len
                    // encodeURI(`https://api.whatsapp.com/send/?phone=${normalizePhone(number)}&text=${replacePatterns(text, user)}&type=phone_number`),
                );
            }
                break;
            case CHANNEL.VIBER: {
                window.open(
                    `viber://chat?number=+${ normalizePhone(viberNumber || whatsappNumber || number) }`,
                );
            }
                break;
            case CHANNEL.SMS: {
                await fetch({
                    numbers: [ number ],
                    text,
                });
                return false;
            }
            case CHANNEL.TELEGRAM: {
                window.open(
                    `tg://resolve?phone=+${ normalizePhone(whatsappNumber || number) }&text=${ encodeURI(text) }`,
                    '_blank',
                );
            }
                break;
        }
        return true;
    }, [ channel, fetch, number, user, viberNumber, whatsappNumber ]);

    const viberClickHandler = useCallback(async () => {
        setChannel(CHANNEL.VIBER);
        await sendHandler(null, CHANNEL.VIBER);
    }, [ sendHandler ]);

    const callClickHandler = useCallback(async () => {
        window.open(`tel:+${ normalizePhone(number) }`, '_blank');
    }, [ number ]);

    if (!number) {
        return null;
    }

    if (inline) {
        return (
            <>
                <UIButton
                    icon={ CHANNEL_ICON.WHATSAPP as any }
                    iconType='brands'
                    iconColor={ CHANNEL_ICON_COLOR.WHATSAPP }
                    onClick={ whatsappClickHandler }
                    size={ inlineIconsSize }
                    className='mr-5'
                />
                <UIButton
                    icon={ CHANNEL_ICON.TELEGRAM as any }
                    iconColor={ CHANNEL_ICON_COLOR.TELEGRAM }
                    iconType='brands'
                    onClick={ telegramClickHandler }
                    size={ inlineIconsSize }
                    className='mr-5'
                />
                <UIButton
                    icon={ CHANNEL_ICON.SMS as any }
                    iconColor={ CHANNEL_ICON_COLOR.SMS }
                    onClick={ SMSClickHandler }
                    size={ inlineIconsSize }
                    className='mr-5'
                />
                <UIButton
                    icon={ CHANNEL_ICON.VIBER as any }
                    iconColor={ CHANNEL_ICON_COLOR.VIBER }
                    iconType='brands'
                    onClick={ viberClickHandler }
                    size={ inlineIconsSize }
                    className='mr-5'
                />
                <UIButton
                    icon='phone'
                    iconColor={ CHANNEL_ICON_COLOR.CALL }
                    onClick={ callClickHandler }
                    size={ inlineIconsSize }
                    className='mr-5'
                />
                <UIButton
                    icon='copy'
                    onClick={ copyClickHandler }
                    size={ inlineIconsSize }
                    className='mr-5'
                />

                { isMessageDialogOpen && (
                    <MessageDialog
                        number={ number }
                        isOpen={ isMessageDialogOpen }
                        onClose={ messageDialogCloseHandler }
                        onSubmit={ sendHandler }
                        text={ text }
                        icon={ CHANNEL_ICON[channel] }
                        iconColor={ CHANNEL_ICON_COLOR[channel] }
                    />
                ) }
            </>
        );
    }
    const options: TOption[] = [
        {
            text: 'Whatsapp',
            value: 'whatsapp',
            icon: CHANNEL_ICON.WHATSAPP,
            iconType: 'brands',
            iconColor: CHANNEL_ICON_COLOR.WHATSAPP,
        },
        {
            text: 'Telegram',
            value: 'telegram',
            icon: CHANNEL_ICON.TELEGRAM,
            iconType: 'brands',
            iconColor: CHANNEL_ICON_COLOR.TELEGRAM,
        },
        {
            text: 'SMS',
            value: 'sms',
            icon: CHANNEL_ICON.SMS,
            iconColor: CHANNEL_ICON_COLOR.SMS,
        },
        {
            text: 'Viber',
            value: 'viber',
            icon: CHANNEL_ICON.VIBER,
            iconType: 'brands',
            iconColor: CHANNEL_ICON_COLOR.VIBER,
        },
    ];

    if (isMobile) {
        options.push({
            text: 'Позвонить',
            value: 'call',
            icon: CHANNEL_ICON.CALL,
            iconColor: CHANNEL_ICON_COLOR.CALL,
        });
    }

    options.push({
        text: 'Копировать',
        value: 'copy',
        icon: 'copy',
        iconColor: 'grey',
    });

    const handlers = {
        'whatsapp': whatsappClickHandler,
        'telegram': telegramClickHandler,
        'sms': SMSClickHandler,
        'viber': viberClickHandler,
        'copy': copyClickHandler,
        'call': callClickHandler,
    };

    const handlerSelectChannel: TSelectHandler = ({ value }) => {
        toggle(false);
        if (value as string in handlers) {
            handlers[value as any]();
        }
    };
    return (
        <>
            <UIPopup.Wrapper>
                <div
                    ref={ ref }
                    className='nowrap cursor-pointer'
                    onClick={ () => toggle(true) }
                    style={ { display: 'flex', alignItems: 'center' } }
                >
                    { formatNumber(number) }
                    <UIIcon name='message-lines' className='ml-5' color='grey' type='light' size='small'/>
                    <MessageDialog
                        number={ number }
                        isOpen={ isMessageDialogOpen }
                        onClose={ messageDialogCloseHandler }
                        onSubmit={ sendHandler }
                        text={ text }

                        icon={ CHANNEL_ICON[channel] }
                        iconColor={ CHANNEL_ICON_COLOR[channel] }
                    />
                </div>
                <UIPopup
                    targetRef={ ref }
                    open={ on }
                    onClickOutside={ () => toggle(false) }
                    minWidth={ 160 }
                >
                    <OptionsList options={ options } onSelect={ handlerSelectChannel }/>
                </UIPopup>
            </UIPopup.Wrapper>
        </>
    );
};

export {
    Phone,
};
