import React, { useEffect } from 'react';
import { omit } from 'lodash';
import { DateTime } from 'luxon';
import { FORMAT } from 'finbox-ui-kit/consts';
import { TInitials } from 'finbox-ui-kit/types';
import { formatPrice, Scheme, SchemeScheme } from '@/utils';
import { RequisitesView } from '@/components/leads/components/requisites/requisites-view';
import { RequisitesSend } from '@/components/leads/components/requisites/requisites-send';
import { API_BASE_URL } from '@/consts';
import TErrors from '@/components/leads/components/lead-task-form';
import { ERequisitesVariant } from '@/components/leads/components/requisites/const';
import { UIInput } from '@/common/finbox-ui-kit/src/components/input';
import { UIButton, UICardsList, UIGrid, UILoadingOverlay, Modal } from '@/common/finbox-ui-kit';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { DatePicker } from '@/common/ui/date-picker';


const SCHEME: SchemeScheme = {
    contractId: {
        type: Scheme.SCHEME_RULE_TYPES.STRING,
        required: true,
    },
    contractDate: {
        type: Scheme.SCHEME_RULE_TYPES.DATE,
        required: true,
        format: FORMAT.DATE,
        errors: {
            format: 'Некорректный формат даты',
        },
    },
    contractSum: {
        type: Scheme.SCHEME_RULE_TYPES.INT,
        required: true,
        min: 0,
    },
};

type TErrors = {
    contractId?: string,
    contractDate?: string,
    contractSum?: string,
}

type TLeadsSendRequisitesProps = {
    open: boolean,
    onClose: () => void,
    clientId: number,
    phone: string,
    contactPhone: string,
    email: string,
    clientInitials: TInitials,
};
export const ClientSendRequisites: React.FC<TLeadsSendRequisitesProps> = ({
    open,
    onClose,
    clientId,
    phone,
    contactPhone,
    email,
    clientInitials,
}) => {
    const [ isRequisitesShowed, setIsRequisitesShowed ] = React.useState(false);
    const [ isSendShowed, setIsSendShowed ] = React.useState(false);
    const [ type, setType ] = React.useState<ERequisitesVariant>(null);

    const [ contractId, setContractId ] = React.useState('');
    const [ contractDate, setContractDate ] = React.useState<DateTime>(null);
    const [ contractSum, setContractSum ] = React.useState('');
    const [ errors, setErrors ] = React.useState<TErrors>({});

    const { loading: isLoading, fetch } = useApiClient({
        url: `${API_BASE_URL}/requisites/${clientId}/contract/`,
    });

    useEffect(() => {
        if (open) {
            setContractId(clientId.toString());
            setType(null);
            setIsRequisitesShowed(false);
            setIsSendShowed(false);
            fetch().then((data) => {
                setContractSum(data.reward);
                setContractDate(data.contractSignDate ? DateTime.fromFormat(data.contractSignDate, FORMAT.DATE) : null);
                setContractId(data.contractID || clientId.toString());
            });
        }
    }, [ open, fetch, clientId ]);

    const handlerSubmit = React.useCallback(() => {
        const { isValid, errors: validationErrors } = Scheme.validate<TErrors>(SCHEME, {
            contractId,
            contractDate,
            contractSum,
        });
        if (!isValid) {
            setErrors(validationErrors);
            return;
        }
        setIsRequisitesShowed(true);
    }, [ contractDate, contractId, contractSum ]);

    const handlerClickSend = React.useCallback(() => {
        setIsRequisitesShowed(false);
        setIsSendShowed(true);
    }, []);

    const handlerClickBack = React.useCallback(() => {
        setIsRequisitesShowed(true);
        setIsSendShowed(false);
    }, []);

    const handlerChangeContractId = (e, { value }) => {
        setContractId(value);
        setErrors((c) => omit(c, 'contractId'));
    };

    const handlerChangeContractDate = (value: DateTime) => {
        setContractDate(value);
        setErrors((c) => omit(c, 'contractDate'));
    };

    const handlerContractSum = (e, { value }) => {
        setContractSum(value);
        setErrors((c) => omit(c, 'contractSum'));
    };

    const handlerClickType = React.useCallback((type: ERequisitesVariant) => () => {
        setType(type);
    }, []);

    return (
        <Modal open={open} onClose={onClose} size={(type && isRequisitesShowed) ? 'large' : 'normal'}>
            <Modal.Header>Реквизиты для оплаты</Modal.Header>
            <Modal.Content>
                {!isRequisitesShowed && !isSendShowed && (
                    <form onSubmit={handlerSubmit} className='relative'>
                        {isLoading && (
                            <UILoadingOverlay active />
                        )}
                        <UIGrid>
                            <UIGrid.Row columns={3}>
                                <UIGrid.Col>
                                    <UIInput
                                        type='text'
                                        label='Номер договора'
                                        name='contractId'
                                        value={contractId}
                                        onChange={handlerChangeContractId}
                                        error={errors.contractId}
                                        required
                                    />
                                </UIGrid.Col>
                                <UIGrid.Col>
                                    <DatePicker
                                        label='Дата договора'
                                        name='contractDate'
                                        value={contractDate}
                                        onChange={handlerChangeContractDate}
                                        error={errors.contractDate}
                                        required
                                    />
                                </UIGrid.Col>
                                <UIGrid.Col>
                                    <UIInput
                                        type='number'
                                        label='Сумма договора'
                                        name='contractSum'
                                        value={contractSum}
                                        onChange={handlerContractSum}
                                        error={errors.contractSum}
                                        postfix='руб.'
                                        renderValueModifier={(value) => formatPrice(value, false)}
                                        required
                                    />
                                </UIGrid.Col>
                            </UIGrid.Row>
                        </UIGrid>
                    </form>
                )}
                {!type && isRequisitesShowed && (
                    <UICardsList columns={2}>
                        <UICardsList.Item
                            title='ИП Фельк Р.В.'
                            subTitle='Договор с клиентом заключен с ИП'
                            // disabled={type === ERequisitesVariant.INDIVIDUAL}
                            onClick={handlerClickType(ERequisitesVariant.INDIVIDUAL)}
                            // basic={type !== ERequisitesVariant.INDIVIDUAL}
                        />
                        <UICardsList.Item
                            title='ООО "Фонд финансирования"'
                            upperTitle='Сбер Банк'
                            subTitle='Договор с клиентом заключен с ООО'
                            // disabled={type === ERequisitesVariant.LEGAL}
                            onClick={handlerClickType(ERequisitesVariant.LEGAL)}
                            // basic={type !== ERequisitesVariant.LEGAL}
                        />
                        <UICardsList.Item
                            title='ООО "Фонд финансирования"'
                            upperTitle='АО "Т-Банк"'
                            subTitle='Договор с клиентом заключен с ООО'
                            // disabled={type === ERequisitesVariant.LEGAL}
                            onClick={handlerClickType(ERequisitesVariant.TBANK)}
                            // basic={type !== ERequisitesVariant.LEGAL}
                        />
                    </UICardsList>
                )}
                {type && isRequisitesShowed && (
                    <RequisitesView
                        type={type}
                        contractId={contractId}
                        contractDate={contractDate.toFormat(FORMAT.DATE)}
                        contractSum={contractSum}
                        clientInitials={clientInitials}
                    />
                )}
                {isSendShowed && (
                    <RequisitesSend
                        type={type}
                        contractId={contractId}
                        contractDate={contractDate.toFormat(FORMAT.DATE)}
                        contractSum={contractSum}
                        phone={phone}
                        contactPhone={contactPhone}
                        email={email}
                        clientInitials={clientInitials}
                    />
                )}
            </Modal.Content>
            <Modal.Actions>
                {!isRequisitesShowed && !isSendShowed && (
                    <UIButton
                        content='Продолжить'
                        onClick={handlerSubmit}
                        disabled={isLoading}
                        primary
                    />
                )}
                {isRequisitesShowed && (
                    <UIButton
                        icon='paper-plane'
                        content='Отправить'
                        onClick={handlerClickSend}
                        primary
                    />
                )}
                {isSendShowed && (
                    <UIButton
                        content='Назад'
                        onClick={handlerClickBack}
                    />
                )}
                <UIButton
                    content='Закрыть'
                    onClick={onClose}
                />
            </Modal.Actions>
        </Modal>
    );
};
