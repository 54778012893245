import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { Outlet } from 'react-router';
import { AuthUser } from '@/state/auth.state';
import { Footer } from '@/common/footer';
import TopMenu from '@/components/top-menu';


const RenderToast = () =>
    ReactDOM.createPortal(
        <ToastContainer
            autoClose={ 5000 }
            style={ { top: 80, zIndex: 999999, filter: 'none !important' } }
            closeOnClick
        />,
        document.getElementById('portal'),
    );

const AppLayout = () => {
    const user = useRecoilValue(AuthUser);

    if (!user) return null;

    return (
        <main>
            <TopMenu isAdmin={ user?.admin } user={ user }/>
            <div className='main-block'>
                <Outlet />
            </div>
            <RenderToast key='com.toast'/>
            <Footer />
        </main>
    );
};

export default AppLayout;
