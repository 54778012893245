import React from 'react';
import { RecoilRoot } from 'recoil';
import i18next from 'i18next';
import { createBrowserRouter } from 'react-router-dom';
import { RouterProvider } from 'react-router';
import { UILoadingOverlay } from 'finbox-ui-kit';
import { FUIModalsProvider } from 'finbox-ui-kit/context/modals.context';
import { CreditFormTranslation } from '@/components/leads/components/credit-from/translation';
import { AuthContextProvider } from '@/context/auth.context';
import { SocketsProvider } from '@/libs/sockets/sockets.context';
import '@/assets/scss/index.scss';
import { JwtProvider } from '@/libs/jwt/jwt.context';
import { API_BASE_URL_V2 } from '@/consts';
import { PublicApiClient } from '@/libs/api-client/public-api-client';
import AppRoot from './app-root';
import ErrorBoundary from './error-boundary';

i18next.init({
    lng: 'ru',
    debug: false,
    resources: {
        ru: {
            translation: CreditFormTranslation,
        },
    },
}).then(() => {
    // console.info('i18n initialized');
});

PublicApiClient.init(API_BASE_URL_V2);

const router = createBrowserRouter([
    {
        path: "*",
        element: <AppRoot/>,
        errorElement: <ErrorBoundary/>,
    },
]);


function App() {
    return (
        <RecoilRoot>
            <JwtProvider refreshTokenUrl={`${ API_BASE_URL_V2 }/auth/refresh-token/`} storageKey={'auth'} updateTokenLag={60}>
                <SocketsProvider>
                    <AuthContextProvider>
                        <FUIModalsProvider>
                            <RouterProvider
                                router={ router }
                                fallbackElement={ <UILoadingOverlay active/> }
                            />
                        </FUIModalsProvider>
                    </AuthContextProvider>
                </SocketsProvider>
            </JwtProvider>
        </RecoilRoot>
    );
}

export default App;
