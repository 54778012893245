import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { DateTime } from 'luxon';
import axios from 'axios';
import { Storage } from '@/utils';
import { decodeAuthData } from '@/libs/jwt/helpers';
import { getFingerprint } from '@/utils/fingerprint';

export type TAccessData = {
    accessToken: string;
    accessTokenExpire: number;
    refreshTokenExpire: number;
}

type TJwtContext = {
    getAccessToken?: () => Promise<string | null>;
    refreshAccessToken?: () => Promise<string>;
    saveTokenData?: (accessToken: string) => boolean;
    destroyJwtData?: () => void;
    registerJwtFailCallback?: (callback: (error: any) => void) => void;
    getStorageToken?: () => string;
};


const JwtContext = React.createContext<TJwtContext>({});

export function useJwtContext(): TJwtContext {
    return React.useContext(JwtContext);
}

type TJwtProps = {
    children: ReactNode;
    refreshTokenUrl: string;
    updateTokenLag: number;
    storageKey: string;
};
export const JwtProvider = ({
    refreshTokenUrl,
    children,
    updateTokenLag,
    storageKey,
}: TJwtProps) => {
    const ref = useRef({
        getTokenInstance: null,
        refreshFailCallback: null,
        request: null,
    });

    useEffect(() => {
        getFingerprint()
            .then((fingerprint) => {
                ref.current.request = axios.create({
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        'X-sec': fingerprint,
                    },
                });
            });
    }, []);

    const isTokenExpired = useCallback(
        (tokenExpire: number) => DateTime.fromMillis(tokenExpire) < DateTime.now().plus({ seconds: updateTokenLag }),
        [ updateTokenLag ],
    );

    const refreshAccessToken: TJwtContext['refreshAccessToken'] = useCallback(async () => {
        const response = await ref.current.request.get(refreshTokenUrl);
        return response.data.token;
    }, [ refreshTokenUrl ]);

    const saveTokenData: TJwtContext['saveTokenData'] = useCallback((token: string) => {
        Storage.setItem(storageKey, decodeAuthData(token));
        return true;
    }, [ storageKey ]);

    const destroyJwtData: TJwtContext['destroyJwtData'] = useCallback(() => {
        Storage.removeItem(storageKey);
    }, [ storageKey ]);


    const processAccessToken: TJwtContext['getAccessToken'] = useCallback(async () => {
        const storageData: TAccessData = Storage.getItem(storageKey);
        if (!storageData) {
            // Access data not found
            throw new Error('Unauthorized');
        }

        if (isTokenExpired(storageData.accessTokenExpire)) {
            console.log({
                refreshTokenExpire: storageData.refreshTokenExpire,
                isTokenExpired: isTokenExpired(storageData.refreshTokenExpire),
            });
            if (isTokenExpired(storageData.refreshTokenExpire)) {
                ref.current.refreshFailCallback && ref.current.refreshFailCallback();
                // Session token expired;
                throw new Error('Unauthorized');
            }

            try {
                const newToken = await refreshAccessToken();
                saveTokenData(newToken);
                return newToken;
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if ([ 400, 401, 403 ].includes(error.response?.status)) {
                        ref.current.refreshFailCallback && ref.current.refreshFailCallback(error);
                    }
                }
                throw error;
            }
        }
        return storageData.accessToken;
    }, [ isTokenExpired, refreshAccessToken, saveTokenData, storageKey ]);

    const getAccessToken: TJwtContext['getAccessToken'] = useCallback(async () => {
        ref.current.getTokenInstance ??= processAccessToken()
            .finally(() => {
                ref.current.getTokenInstance = null;
            });
        return ref.current.getTokenInstance;
    }, [ processAccessToken ]);

    const registerJwtFailCallback: TJwtContext['registerJwtFailCallback'] = useCallback((callback: (error: any) => void) => {
        ref.current.refreshFailCallback = callback;
    }, []);

    const getStorageToken: TJwtContext['getStorageToken'] = useCallback(() => {
        const storageData: TAccessData = Storage.getItem(storageKey);
        return storageData?.accessToken;
    }, [ storageKey ]);

    return (
        <JwtContext.Provider value={ {
            getAccessToken,
            refreshAccessToken,
            saveTokenData,
            destroyJwtData,
            registerJwtFailCallback,
            getStorageToken,
        } }>
            { children }
        </JwtContext.Provider>
    );
};
