import React, { useMemo } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { DateTime } from 'luxon';

import { UILoader } from 'finbox-ui-kit';
import Login from '@/components/login';
import { NewLeadNotification } from "@/components/notifications/new-lead";
import { AuthState, AuthUser } from '@/state/auth.state';
import { ChangePasswordRequired } from '@/components/settings/users/components/change-password-required';
import IncomingCall from '@/components/calls/components/incoming-call';
import { useAuthContext } from '@/context/auth.context';
import { ClientTasksContextProvider } from '@/context/client-tasks.context';
import { ClientContextProvider } from '@/context/client.context';
import { AppContextProvider } from '@/context/app.context';

import { TopButton } from '@/common/top-button';
import { AppRoutes } from '@/app-routes';
import { NotificationsProvider } from '@/context/notifications.context';


const AppRoot = () => {
    const { isLoadingCheckAuth } = useAuthContext();
    const isAuth = useRecoilValue(AuthState);
    const user = useRecoilValue(AuthUser);

    const isNeedPasswordChange = useMemo(() => {
        if (user && !user.admin) {
            return DateTime.fromISO(user.last_change_password) < DateTime.now().minus({ month: 1 });
        }
        return false;
    }, [ user ]);
    return (
        <>
            {isLoadingCheckAuth && (
                <div style={ {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 1000,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                } }>
                    <UILoader/>
                </div>
            ) }
            { !isAuth && (
                <Login/>
            ) }
            { isAuth && !isLoadingCheckAuth && (
                <AppContextProvider>
                    <NotificationsProvider>
                        <NewLeadNotification/>
                        <AppRoutes/>
                        <ClientContextProvider>
                            <ClientTasksContextProvider>
                                <IncomingCall/>
                            </ClientTasksContextProvider>
                        </ClientContextProvider>
                        { isNeedPasswordChange && (
                            <ChangePasswordRequired/>
                        ) }
                        <TopButton/>
                    </NotificationsProvider>
                </AppContextProvider>
            ) }
            <ScrollRestoration/>
        </>
    );
};


export default AppRoot;
