/* eslint-disable no-console */
import React, { ReactNode, useCallback, useEffect } from 'react';
import { useJwtContext } from '@/libs/jwt/jwt.context';
import { socket } from '@/socket';


type TSocketsContext = {
    connect(): void;
    disconnect(): void;
    subscribe(event: string, callback: (data: any) => void): void;
    unsubscribe(event: string, callback: (data: any) => void): void;
};


const SocketsContext = React.createContext<TSocketsContext>({} as TSocketsContext);

export function useSocketsContext() {
    return React.useContext(SocketsContext);
}

type TSocketsProps = {
    children?: ReactNode
};
export const SocketsProvider = ({ children }: TSocketsProps) => {
    const { getStorageToken } = useJwtContext();
    useEffect(() => {
        console.log('[Sockets] INIT SOCKETS');
        socket.auth = (cb) => {
            cb({ token: getStorageToken() });
        };

        socket.on('connect', () => {
            console.log('[Sockets] connected');
        });
        return () => {
            socket.disconnect();
        };
    }, [ getStorageToken ]);


    const connect = useCallback(() => {
        console.log('[Sockets] connect');
        socket.connect();
    }, []);

    const disconnect = useCallback(() => {
        socket.disconnect();
    }, []);

    const subscribe = useCallback((event: string, callback: (data: any) => void) => {
        console.log('[Sockets] subscribe', event);
        socket.on(event, callback);
    }, []);

    const unsubscribe = useCallback((event: string, callback: (data: any) => void) => {
        console.log('[Sockets] unsubscribe', event);
        socket.off(event, callback);
    }, []);

    return (
        <SocketsContext.Provider value={ {
            connect,
            disconnect,
            subscribe,
            unsubscribe,
        } }>
            { children }
        </SocketsContext.Provider>
    );
};
