import React, { useImperativeHandle, useRef } from 'react';
import { FieldError, Merge } from 'react-hook-form';
import { Nullable } from '@/types/_helpers';
import { TInputHandlerChange, TInputProps } from '@/common/ui/input/input.types';
import { Input } from '@/common/ui/input';
import { ErrorText } from '@/common/ui/error-text';
import styles from './input-range.module.scss';


export type TInputRangeValue = Nullable<[ Nullable<number>, Nullable<number> ]>;
export type TInputRangeError = Merge<FieldError, [ (FieldError | undefined)?, (FieldError | undefined)? ]> | undefined;

const getValue = (currentValue: TInputRangeValue, value: number, index: number): TInputRangeValue => {
    const _value: TInputRangeValue = currentValue ? [ ...currentValue ] : [ null, null ];
    if (value === null && _value[1 - index] === null) {
        return null;
    }
    _value[index] = value;
    return _value;
}


function getErrorText(error: TInputRangeError) {
    if (Array.isArray(error)) {
        return error.find((i) => i?.message)?.message;
    }
    return error?.message;
}

type InputRangeProps = {
    value: TInputRangeValue
    onChange: (range: TInputRangeValue) => void
    error?: TInputRangeError
} & Pick<TInputProps,
    'name' |
    'label' |
    'postfix' |
    'renderValue' |
    'required' |
    'disabled' |
    'float' |
    'clearable' |
    'placeholder' |
    'size' |
    'loading' |
    'readOnly'>;

export const InputRange = React.forwardRef<HTMLInputElement, InputRangeProps>(function InputRange({
    value,
    onChange,
    postfix,
    renderValue,
    required,
    disabled,
    readOnly,
    label,
    name,
    error,
    clearable,
    placeholder,
    size,
    loading,
    float,
}, forwardRef) {
    const inputRef = useRef(null);
    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(forwardRef, () => inputRef.current, []);
    const handlerChangeFrom: TInputHandlerChange<number> = (_, { value: inputValue }) => {
        onChange(getValue(value, inputValue, 0));
    };
    const handlerChangeTo: TInputHandlerChange<number> = (_, { value: inputValue }) => {
        onChange(getValue(value, inputValue, 1));
    };

    const inputProps = {
        postfix,
        renderValue,
        required,
        disabled,
        readOnly,
        clearable,
        placeholder,
        size,
        loading,
        float,
    };

    return (
        <div className={ styles.inputRange }>
            <div className={ styles.inputRangeWrapper }>
                <input ref={ inputRef } type='hidden'/>
                <Input
                    ref={ inputRef }
                    type='number'
                    className={ styles.inputRangeInput }
                    name={ `${ name }_from` }
                    label={ `${ label } от` }
                    onChange={ handlerChangeFrom }
                    value={ value ? value[0] : null }
                    error={ !!error }
                    { ...inputProps }
                />
                <Input
                    type='number'
                    className={ styles.inputRangeInput }
                    name={ `${ name }_to` }
                    label={ `${ label } до` }
                    onChange={ handlerChangeTo }
                    value={ value ? value[1] : null }
                    error={ !!error }
                    { ...inputProps }
                />
            </div>

            { error && (
                <ErrorText text={ getErrorText(error) }/>
            ) }
        </div>
    );
});