import React, { useMemo, useRef, useState } from 'react';
import i18next from 'i18next';
import { UIButton, UIPopup } from 'finbox-ui-kit';
import { useToggle } from 'finbox-ui-kit/utils/hooks';
import { Modal, useModal } from '@/common/ui';
import { useDownloadFile } from '@/utils/hooks/use-download-file';
import { validateFullForm, validateFullOrder } from '@/components/leads/components/credit-from/validation';
import { ECreditFormType } from '@/components/leads/components/credit-from/consts';
import { transformUrlParams } from '@/utils';
import { API_URLS } from '@/consts';
import { OptionsList, TOption } from '@/common/options-list';
import { useApiClient } from '@/libs/api-client/use-api-client';

export const AttachOrder = ({ clientId, user, onAttach }) => {
    const { data: client, loading: isLoading } = useApiClient({
        url: transformUrlParams(API_URLS.CLIENTS.ITEM, { id: clientId }),
        initialFetch: true,
    });

    const [ downloadErrors, setDownloadErrors ] = useState<Record<string, string>>(null);
    const { isLoading: isLoadingDownloadOrder, download: downloadPledgeObjectCard } = useDownloadFile();
    const { isLoading: isLoadingDownloadForm, download: downloadCreditForm } = useDownloadFile();
    const { isLoading: isLoadingDownloadDocuments, download: downloadPledgeObjectDocuments } = useDownloadFile();
    const { show, props } = useModal({
        onClose: () => {
            setDownloadErrors(null);
        },
    });
    const { on, toggle } = useToggle();
    const ref = useRef<HTMLButtonElement>();

    const handlerAttachClick = React.useCallback(async (type: 'order' | 'form', hiddenContacts: boolean) => {
        const files = [];
        const isOrder = type === 'order';
        const downloader = isOrder ? downloadPledgeObjectCard : downloadCreditForm;
        const link = isOrder ? API_URLS.LEADS.PLEDGE_OBJECT_DOWNLOAD : API_URLS.LEADS.CREDITOR_FROM_DOWNLOAD;
        const validator = isOrder ? validateFullOrder : validateFullForm;

        const { isValid, errors } = validator(client, ECreditFormType.LOAN, user);
        if (isValid) {
            const form = await downloader(
                transformUrlParams(link, { id: clientId }) + `?hiddenContacts=${ hiddenContacts }`,
                false,
                true,
            );
            files.push(form);
            if (!hiddenContacts) {
                if ((client?.pledgeObjects || []).some((i: any) => i.documentsImages.length)) {
                    const docs = await downloadPledgeObjectDocuments(
                        transformUrlParams(API_URLS.LEADS.PLEDGE_OBJECT_DOWNLOAD_DOCUMENTS, { id: clientId }),
                        false,
                        true,
                    );
                    files.push(docs);
                }
            }
        } else {
            setDownloadErrors(errors);
            show();
        }
        onAttach(files);
    }, [ client, clientId, downloadCreditForm, downloadPledgeObjectCard, downloadPledgeObjectDocuments, onAttach, user, show ]);


    const options = useMemo<TOption[]>(() => [
        {
            text: 'Заявка',
            value: 'order',
            items: [
                {
                    text: 'С контактами',
                    value: 'order_contacts',
                },
                {
                    text: 'Без контактов',
                    value: 'order_no_contacts',
                },
            ],
        },
        {
            text: 'Анкета',
            value: 'form',
            items: [
                {
                    text: 'С контактами',
                    value: 'form_contacts',
                },
                {
                    text: 'Без контактов',
                    value: 'form_no_contacts',
                },
            ],
        },
    ], []);
    const handlerClickOption = async (item: any) => {
        toggle(false);
        const [ type, contacts ] = item.value.split('_');
        await handlerAttachClick(type, contacts === 'contacts');
    };
    return (
        <div className='mb1'>
            <UIPopup.Wrapper>
                <UIButton
                    ref={ ref }
                    type='button'
                    icon='link'
                    content='Прикрепить заявку'
                    onClick={ () => toggle() }
                    loading={ isLoading || isLoadingDownloadOrder || isLoadingDownloadDocuments || isLoadingDownloadForm }
                />
                <UIPopup targetRef={ ref } open={ on } onClickOutside={ () => toggle(false) } style={ { zIndex: 9 } }>
                    <OptionsList options={ options } onSelect={ handlerClickOption }/>
                </UIPopup>
            </UIPopup.Wrapper>

            <div className='clear'/>
            <Modal
                { ...props }
                showCloseButton
            >
                <Modal.Header>Ошибки заполнения</Modal.Header>
                <Modal.Content>
                    Для скачивания необходимо исправить следующие ошибки:
                    <ul>
                        { Object.entries(downloadErrors || {}).map(([ key, error ]) => (
                            <li key={ key }>
                                <b>{ i18next.t(key, key) as string }:</b> { error }
                            </li>
                        )) }
                    </ul>
                </Modal.Content>
                <Modal.Actions>
                    <UIButton content='Закрыть' onClick={() => props.onClose()} />
                </Modal.Actions>
            </Modal>
        </div>
    );
};
